import { gql } from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

/** Autogenerated input type of ApplyCouponCode */
export type ApplyCouponCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  couponCode: Scalars["String"]["input"];
};

export enum AreaTypeEnum {
  /** 結合エリア */
  CombinedArea = "COMBINED_AREA",
  /** 単体エリア */
  MasterArea = "MASTER_AREA",
  /** 都道府県 */
  MasterPrefecture = "MASTER_PREFECTURE",
}

/** Autogenerated input type of CancelReservation */
export type CancelReservationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  reservationUuid: Scalars["String"]["input"];
};

export type CancelReservationResult = UsersReservationType;

/** Autogenerated input type of CompleteReservationRequest */
export type CompleteReservationRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  reservationUuid: Scalars["String"]["input"];
};

export type CompleteReservationRequestResult = UsersReservationType;

/** Autogenerated input type of ConfirmUserPolicy */
export type ConfirmUserPolicyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  policyType: UserPolicyTypeEnum;
  policyVersion: UserPolicyVersionEnum;
};

/** Autogenerated input type of CreateEmailOptInSetting */
export type CreateEmailOptInSettingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  isAllowedEmail: Scalars["Boolean"]["input"];
};

/** Autogenerated input type of CreateUserFavoriteShop */
export type CreateUserFavoriteShopInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  shopUuid: Scalars["String"]["input"];
};

/** Autogenerated input type of CreateUserFavoriteTeam */
export type CreateUserFavoriteTeamInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  teamId: Scalars["Int"]["input"];
};

/** Autogenerated input type of DeletePaymentMethod */
export type DeletePaymentMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  stripePaymentMethodId: Scalars["String"]["input"];
};

export type DeletePaymentMethodResult = UsersPayloadsSuccessPayload;

/** Autogenerated input type of DeleteUserFavoriteShop */
export type DeleteUserFavoriteShopInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  shopUuid: Scalars["String"]["input"];
};

/** Autogenerated input type of DeleteUserFavoriteTeam */
export type DeleteUserFavoriteTeamInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  teamId: Scalars["Int"]["input"];
};

/** Autogenerated input type of DeleteUserPushNotificationToken */
export type DeleteUserPushNotificationTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  token: Scalars["String"]["input"];
};

/** Autogenerated input type of DrawLottery */
export type DrawLotteryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  lotteryEntryUuid: Scalars["String"]["input"];
};

/** Autogenerated input type of ExchangeGift */
export type ExchangeGiftInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  giftId: Scalars["Int"]["input"];
};

export type GameWatchedCountInput = {
  masterSportSeasonId: Scalars["Int"]["input"];
  number: Scalars["Int"]["input"];
};

export enum GifteeGiftExchangeStateEnum {
  Done = "DONE",
  Expired = "EXPIRED",
  Pending = "PENDING",
}

/** Autogenerated input type of Leave */
export type LeaveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  leaveReason: Scalars["String"]["input"];
};

export enum LotteryEntryStateEnum {
  Canceled = "CANCELED",
  Done = "DONE",
  Expired = "EXPIRED",
  Pending = "PENDING",
}

export enum LotteryPrizeMovieTypeEnum {
  BigWin = "BIG_WIN",
  Lose = "LOSE",
  MiddleWin = "MIDDLE_WIN",
  SmallWin = "SMALL_WIN",
}

export enum MasterTournamentTypeEnum {
  Section = "SECTION",
  Tournament = "TOURNAMENT",
}

export type PaymentCardsResult = UsersPayloadsPaymentCardsPayload;

/** Autogenerated input type of ReadNotification */
export type ReadNotificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  userNotificationId: Scalars["Int"]["input"];
};

/** Autogenerated input type of ReadNotifications */
export type ReadNotificationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readsAt: Scalars["String"]["input"];
};

/** Autogenerated input type of RecreateUserShopRating */
export type RecreateUserShopRatingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  rating: Scalars["Int"]["input"];
  shopUuid: Scalars["String"]["input"];
};

/** Autogenerated input type of RegisterPushToken */
export type RegisterPushTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  token: Scalars["String"]["input"];
};

export type RequestReservationResult =
  | RequestReservationResult_ReservationFrameInactiveError
  | RequestReservationResult_ReservationFrameNotFoundError
  | RequestReservationResult_ReservationPlanInconsistentError
  | RequestReservationResult_ShopReservationStatusInactiveError
  | UsersReservationType;

export type RequestReservationResultV3 =
  | RequestReservationResultV3_ReservationFrameInactiveError
  | RequestReservationResultV3_ReservationFrameNotFoundError
  | RequestReservationResultV3_ReservationPaymentError
  | RequestReservationResultV3_ReservationPlanInconsistentError
  | RequestReservationResultV3_ShopReservationStatusInactiveError
  | UsersPayloadsRequestReservationPayload;

export type RequestReservationResultV3_ReservationFrameInactiveError = {
  __typename?: "RequestReservationResultV3_ReservationFrameInactiveError";
  message: Scalars["String"]["output"];
};

export type RequestReservationResultV3_ReservationFrameNotFoundError = {
  __typename?: "RequestReservationResultV3_ReservationFrameNotFoundError";
  message: Scalars["String"]["output"];
};

export type RequestReservationResultV3_ReservationPaymentError = {
  __typename?: "RequestReservationResultV3_ReservationPaymentError";
  message: Scalars["String"]["output"];
};

export type RequestReservationResultV3_ReservationPlanInconsistentError = {
  __typename?: "RequestReservationResultV3_ReservationPlanInconsistentError";
  message: Scalars["String"]["output"];
};

export type RequestReservationResultV3_ShopReservationStatusInactiveError = {
  __typename?: "RequestReservationResultV3_ShopReservationStatusInactiveError";
  message: Scalars["String"]["output"];
};

export type RequestReservationResult_ReservationFrameInactiveError = {
  __typename?: "RequestReservationResult_ReservationFrameInactiveError";
  message: Scalars["String"]["output"];
};

export type RequestReservationResult_ReservationFrameNotFoundError = {
  __typename?: "RequestReservationResult_ReservationFrameNotFoundError";
  message: Scalars["String"]["output"];
};

export type RequestReservationResult_ReservationPlanInconsistentError = {
  __typename?: "RequestReservationResult_ReservationPlanInconsistentError";
  message: Scalars["String"]["output"];
};

export type RequestReservationResult_ShopReservationStatusInactiveError = {
  __typename?: "RequestReservationResult_ShopReservationStatusInactiveError";
  message: Scalars["String"]["output"];
};

/** Autogenerated input type of RequestReservationV2 */
export type RequestReservationV2Input = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  comesAt: Scalars["String"]["input"];
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameKana?: InputMaybe<Scalars["String"]["input"]>;
  isApp?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameKana?: InputMaybe<Scalars["String"]["input"]>;
  personNum: Scalars["Int"]["input"];
  reservationFrameId: Scalars["Int"]["input"];
  reservationPlanLockVersion: Scalars["Int"]["input"];
  reservationSupportingTeam?: InputMaybe<ReservationSupportingTeamInput>;
  tel: Scalars["String"]["input"];
};

/** Autogenerated input type of RequestReservationV3 */
export type RequestReservationV3Input = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  comesAt: Scalars["String"]["input"];
  confirmationToken?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameKana?: InputMaybe<Scalars["String"]["input"]>;
  isApp?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameKana?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethodId?: InputMaybe<Scalars["String"]["input"]>;
  paymentType: ReservationPaymentTypeEnum;
  personNum: Scalars["Int"]["input"];
  pointsToApply?: InputMaybe<Scalars["Int"]["input"]>;
  reservationFrameId: Scalars["Int"]["input"];
  reservationPlanLockVersion: Scalars["Int"]["input"];
  reservationSupportingTeam?: InputMaybe<ReservationSupportingTeamInput>;
  savesPaymentMethod?: InputMaybe<Scalars["Boolean"]["input"]>;
  tel: Scalars["String"]["input"];
};

export enum ReservationCancelTypeEnum {
  ByMatchCanceled = "BY_MATCH_CANCELED",
  ByMatchRescheduled = "BY_MATCH_RESCHEDULED",
  ByMatchTimeTba = "BY_MATCH_TIME_TBA",
  ByShop = "BY_SHOP",
  ByUser = "BY_USER",
  ByUserV2 = "BY_USER_V2",
  WithoutPermission = "WITHOUT_PERMISSION",
}

export enum ReservationCancelTypeEnumV2 {
  ByMatchCanceled = "BY_MATCH_CANCELED",
  ByMatchRescheduled = "BY_MATCH_RESCHEDULED",
  ByMatchTimeTba = "BY_MATCH_TIME_TBA",
  ByShopDueToNoShow = "BY_SHOP_DUE_TO_NO_SHOW",
  ByShopDueToShop = "BY_SHOP_DUE_TO_SHOP",
  ByShopDueToUser = "BY_SHOP_DUE_TO_USER",
  ByUser = "BY_USER",
  PaymentError = "PAYMENT_ERROR",
}

export enum ReservationFrameStateTypeEnum {
  Active = "ACTIVE",
  Full = "FULL",
  Restricted = "RESTRICTED",
  Suspended = "SUSPENDED",
}

export enum ReservationFrameTypeEnum {
  Request = "REQUEST",
}

export enum ReservationPaymentMethodTypeEnum {
  Card = "CARD",
  FullPointsPayment = "FULL_POINTS_PAYMENT",
}

export enum ReservationPaymentTypeEnum {
  OnSite = "ON_SITE",
  OnSystem = "ON_SYSTEM",
}

export enum ReservationPlanAllowedPaymentTypeEnum {
  Any = "ANY",
  OnSite = "ON_SITE",
  OnSystem = "ON_SYSTEM",
}

export enum ReservationStateTypeEnum {
  Canceled = "CANCELED",
  Confirmed = "CONFIRMED",
  Pending = "PENDING",
  Rejected = "REJECTED",
  Visited = "VISITED",
}

export type ReservationSupportingTeamInput = {
  supportingTeamId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Autogenerated input type of SaveUserPushNotificationToken */
export type SaveUserPushNotificationTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  token: Scalars["String"]["input"];
};

/** Autogenerated input type of SendRegisteredMail */
export type SendRegisteredMailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ShopReservabilityTypeEnum {
  /** 満席 */
  Full = "FULL",
  /** 無効 */
  None = "NONE",
  /** ネット予約可能 */
  Reservable = "RESERVABLE",
  /** TEL */
  Tel = "TEL",
}

/** Autogenerated input type of Signup */
export type SignupInput = {
  birthdate: Scalars["String"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  gameWatchedCountInputs?: InputMaybe<Array<GameWatchedCountInput>>;
  isAllowedEmail?: InputMaybe<Scalars["Boolean"]["input"]>;
  masterCityId: Scalars["Int"]["input"];
  nickname: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
  userConfirmedPolicyInputs?: InputMaybe<Array<UserConfirmedPolicyInput>>;
};

/** Autogenerated input type of UpdateEmail */
export type UpdateEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
};

/** Autogenerated input type of UpdateEmailNotificationSetting */
export type UpdateEmailNotificationSettingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  coupon?: InputMaybe<Scalars["Boolean"]["input"]>;
  favoriteTeam?: InputMaybe<Scalars["Boolean"]["input"]>;
  favoriteTeamMatch?: InputMaybe<Scalars["Boolean"]["input"]>;
  fromAdmin: Scalars["Boolean"]["input"];
};

/** Autogenerated input type of UpdateFavoriteTeams */
export type UpdateFavoriteTeamsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  teamIds: Array<Scalars["Int"]["input"]>;
};

/** Autogenerated input type of UpdateProfile */
export type UpdateProfileInput = {
  birthdate: Scalars["String"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameKana?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameKana?: InputMaybe<Scalars["String"]["input"]>;
  masterCityId: Scalars["Int"]["input"];
  nickname: Scalars["String"]["input"];
};

/** Autogenerated input type of UpdatePushNotificationSetting */
export type UpdatePushNotificationSettingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  coupon?: InputMaybe<Scalars["Boolean"]["input"]>;
  favoriteTeam?: InputMaybe<Scalars["Boolean"]["input"]>;
  favoriteTeamMatch?: InputMaybe<Scalars["Boolean"]["input"]>;
  fromAdmin: Scalars["Boolean"]["input"];
};

/** Autogenerated input type of UseCoupon */
export type UseCouponInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  userCouponId: Scalars["Int"]["input"];
};

export type UserConfirmedPolicyInput = {
  policyType: UserPolicyTypeEnum;
  policyVersion: UserPolicyVersionEnum;
};

export type UserGiftExchange = {
  /** 商品交換日時 */
  createdAt: Scalars["String"]["output"];
  /** 商品 */
  gift: UsersGiftType;
};

export type UserGiftExchangePage = {
  __typename?: "UserGiftExchangePage";
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  nodes: Array<UserGiftExchange>;
  totalCount: Scalars["Int"]["output"];
  totalPage: Scalars["Int"]["output"];
};

export enum UserNotificationIconTypeEnum {
  /** FANSTAアイコン */
  Fansta = "FANSTA",
  /** 一般懸賞用のアイコン */
  Lottery = "LOTTERY",
}

export type UserPointHistory = {
  /** 適用した日時（付与日、処理日、失効日、発行日にあたるもの） */
  appliedAt: Scalars["String"]["output"];
  /** 〇〇日を示すラベル */
  appliedAtLabel: Scalars["String"]["output"];
  /** ID */
  id: Scalars["Int"]["output"];
  /** ラベル名 */
  label: Scalars["String"]["output"];
  /** 加算or減算されたポイント数 */
  points: Scalars["Int"]["output"];
};

export type UserPointHistoryPage = {
  __typename?: "UserPointHistoryPage";
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  nodes: Array<UserPointHistory>;
  totalCount: Scalars["Int"]["output"];
  totalPage: Scalars["Int"]["output"];
};

export enum UserPolicyTypeEnum {
  Points = "POINTS",
  Privacy = "PRIVACY",
}

export enum UserPolicyVersionEnum {
  V2022_10_24App = "V2022_10_24_APP",
  V2022_10_24Unknown = "V2022_10_24_UNKNOWN",
  V2022_10_24Web = "V2022_10_24_WEB",
  V2023_11_17 = "V2023_11_17",
}

export type UsersAreaType = {
  __typename?: "UsersAreaType";
  /** master_area_id */
  id: Scalars["Int"]["output"];
  /** 地区に所属する都道府県 */
  masterPrefecture?: Maybe<UsersMasterPrefectureType>;
  /** 地区名 */
  name: Scalars["String"]["output"];
  /** エリアに所属する店舗 */
  shops?: Maybe<Array<UsersShopType>>;
  /** area_slug */
  slug?: Maybe<Scalars["String"]["output"]>;
};

export type UsersAreaTypePage = {
  __typename?: "UsersAreaTypePage";
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  nodes: Array<UsersAreaType>;
  totalCount: Scalars["Int"]["output"];
  totalPage: Scalars["Int"]["output"];
};

export type UsersBenefitType = {
  __typename?: "UsersBenefitType";
  /** 本文 */
  content: Scalars["String"]["output"];
  /** 表示名 */
  displayText: Scalars["String"]["output"];
  /** 店舗特典ID */
  id: Scalars["Int"]["output"];
  /** 特典識別子 */
  name: Scalars["String"]["output"];
  /** タイトル */
  title: Scalars["String"]["output"];
};

export type UsersCampaignLabelType = {
  __typename?: "UsersCampaignLabelType";
  /** 表示名 */
  displayText: Scalars["String"]["output"];
  /** バッジ画像パス */
  iconPath?: Maybe<Scalars["String"]["output"]>;
  /** キャンペーンラベルID */
  id: Scalars["Int"]["output"];
  /** 遷移先URL */
  linkUrl?: Maybe<Scalars["String"]["output"]>;
  /** ラベル識別子 */
  name: Scalars["String"]["output"];
  /** 表示順 */
  sort: Scalars["Int"]["output"];
};

export type UsersCategorizedDaznOfficialShopsType = {
  __typename?: "UsersCategorizedDaznOfficialShopsType";
  /** カテゴリ名 */
  category: Scalars["String"]["output"];
  /** DAZN公式契約店一覧 */
  shops: Array<UsersDaznOfficialShopType>;
};

export type UsersCombinedAreaType = {
  __typename?: "UsersCombinedAreaType";
  /** 結合エリアに所属する単体エリア */
  areas?: Maybe<Array<UsersAreaType>>;
  /** combined_area_id */
  id: Scalars["Int"]["output"];
  /** 都道府県 */
  masterPrefecture?: Maybe<UsersMasterPrefectureType>;
  /** 都道府県ID */
  masterPrefectureId?: Maybe<Scalars["Int"]["output"]>;
  /** エリア名 */
  name: Scalars["String"]["output"];
  /** エリアに所属する店舗 */
  shops?: Maybe<Array<UsersShopType>>;
  /** combined_area_slug */
  slug?: Maybe<Scalars["String"]["output"]>;
};

export type UsersCombinedAreaTypePage = {
  __typename?: "UsersCombinedAreaTypePage";
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  nodes: Array<UsersCombinedAreaType>;
  totalCount: Scalars["Int"]["output"];
  totalPage: Scalars["Int"]["output"];
};

export type UsersCouponType = {
  __typename?: "UsersCouponType";
  /** クーポン種別 */
  couponType: Scalars["String"]["output"];
  /** 表示名 */
  displayName: Scalars["String"]["output"];
  /** id */
  id: Scalars["Int"]["output"];
  /** クーポン識別子 */
  name: Scalars["String"]["output"];
  /** クーポン内容 */
  notes: Scalars["String"]["output"];
  /** 対象店舗の説明 */
  targetShopDescription: Scalars["String"]["output"];
};

export type UsersCurrentUserType = {
  __typename?: "UsersCurrentUserType";
  /** ユーザー情報 */
  user?: Maybe<UsersUserType>;
};

export type UsersDaznOfficialShopResultType = {
  __typename?: "UsersDaznOfficialShopResultType";
  /** DaznOfficialShopTypes */
  categorizedShops: Array<UsersCategorizedDaznOfficialShopsType>;
  /** DAZN公式契約店一覧データ受領日時 */
  receivedAt?: Maybe<Scalars["String"]["output"]>;
  totalCount: Scalars["Int"]["output"];
};

export type UsersDaznOfficialShopType = {
  __typename?: "UsersDaznOfficialShopType";
  /** 住所 */
  address: Scalars["String"]["output"];
  /** カテゴリ */
  category: Scalars["String"]["output"];
  /** dazn_official_shop_id */
  id: Scalars["Int"]["output"];
  /** 店舗名 */
  name: Scalars["String"]["output"];
  /** 郵便番号 */
  postcode: Scalars["String"]["output"];
  /** 電話番号 */
  tel: Scalars["String"]["output"];
  /** 電話番号(ハイフン付き) */
  telWithHyphen: Scalars["String"]["output"];
  /** 店舗URL */
  url: Scalars["String"]["output"];
};

export type UsersFanstaConfigType = {
  __typename?: "UsersFanstaConfigType";
  /** フィーチャーの設定 */
  features: UsersFanstaConfigsFeaturesType;
  /** PRする一般懸賞の設定 */
  highlightLotteryCampaign: UsersFanstaConfigsHighlightLotteryCampaignType;
  /** ロッテのチームページの設定 */
  marinesTeamPage: UsersFanstaConfigsMarinesTeamPageType;
  /** トップページの設定 */
  topPage: UsersFanstaConfigsTopPageType;
};

export type UsersFanstaConfigsFeaturesType = {
  __typename?: "UsersFanstaConfigsFeaturesType";
  /** 事前決済機能のフィーチャーフラグ */
  payments: Scalars["Boolean"]["output"];
  /** ポイント機能のフィーチャーフラグ */
  points: Scalars["Boolean"]["output"];
};

export type UsersFanstaConfigsHighlightLotteryCampaignType = {
  __typename?: "UsersFanstaConfigsHighlightLotteryCampaignType";
  /** lottery_campaign_id */
  id: Scalars["Int"]["output"];
  /** PR画像URL */
  imageUrl: Scalars["String"]["output"];
  /** 遷移先URL */
  linkUrl: Scalars["String"]["output"];
  /** PR文言 */
  text: Scalars["String"]["output"];
};

export type UsersFanstaConfigsMarinesTeamPageType = {
  __typename?: "UsersFanstaConfigsMarinesTeamPageType";
  /** ロッテのチームページのボタン用のURL */
  linkUrl: Scalars["String"]["output"];
};

export type UsersFanstaConfigsMatchSectionType = {
  __typename?: "UsersFanstaConfigsMatchSectionType";
  /** トーナメントごとに何件の試合を表示するか */
  limitOfMatches: Scalars["Int"]["output"];
  /** セクションに表示するトーナメント */
  tournaments: Array<UsersFanstaConfigsTopPageTournamentType>;
};

export type UsersFanstaConfigsTeamSectionType = {
  __typename?: "UsersFanstaConfigsTeamSectionType";
  /** セクションに表示するトーナメント */
  tournamentIds: Array<Scalars["Int"]["output"]>;
};

export type UsersFanstaConfigsTopPageTournamentType = {
  __typename?: "UsersFanstaConfigsTopPageTournamentType";
  /** 特定のチームの試合のみ表示する場合に使用するチームのID */
  filteringTeamIds: Array<Scalars["Int"]["output"]>;
  /** 2週間後までの試合に絞り込むかどうか */
  shouldFilterByTwoWeeks: Scalars["Boolean"]["output"];
  /** 時間・チーム未定の試合を含めるかどうか */
  shouldIncludeTba: Scalars["Boolean"]["output"];
  /** トーナメントID */
  tournamentId: Scalars["Int"]["output"];
};

export type UsersFanstaConfigsTopPageType = {
  __typename?: "UsersFanstaConfigsTopPageType";
  /** 試合セクションの設定 */
  matchSection: UsersFanstaConfigsMatchSectionType;
  /** チームセクションの設定 */
  teamSection: UsersFanstaConfigsTeamSectionType;
};

export type UsersGiftType = {
  __typename?: "UsersGiftType";
  /** 商品説明文 */
  description: Scalars["String"]["output"];
  /** 商品有効期限の説明 */
  expiration?: Maybe<Scalars["String"]["output"]>;
  /** 商品ID */
  id: Scalars["Int"]["output"];
  /** 商品画像URL */
  imageUrl: Scalars["String"]["output"];
  /** 商品名 */
  name: Scalars["String"]["output"];
  /** 交換に必要なポイント */
  requiredPoints?: Maybe<Scalars["Int"]["output"]>;
};

export type UsersGiftTypePage = {
  __typename?: "UsersGiftTypePage";
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  nodes: Array<UsersGiftType>;
  totalCount: Scalars["Int"]["output"];
  totalPage: Scalars["Int"]["output"];
};

export type UsersHolidayType = {
  __typename?: "UsersHolidayType";
  /** 日付 */
  date: Scalars["String"]["output"];
};

export type UsersLotteryCampaignType = {
  __typename?: "UsersLotteryCampaignType";
  description: Scalars["String"]["output"];
  keyVisualUrl: Scalars["String"]["output"];
  maxPoints: Scalars["Int"]["output"];
  shareLinkUrl: Scalars["String"]["output"];
  startsAt: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type UsersLotteryEntryType = {
  __typename?: "UsersLotteryEntryType";
  lotteryCampaign: UsersLotteryCampaignType;
  lotteryPrize?: Maybe<UsersLotteryPrizeType>;
  state: LotteryEntryStateEnum;
  uuid: Scalars["String"]["output"];
};

export type UsersLotteryPrizeType = {
  __typename?: "UsersLotteryPrizeType";
  description?: Maybe<Scalars["String"]["output"]>;
  imageUrl: Scalars["String"]["output"];
  movieType: LotteryPrizeMovieTypeEnum;
  shareDescription: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type UsersMasterCityType = {
  __typename?: "UsersMasterCityType";
  /** city_code */
  cityCode: Scalars["String"]["output"];
  /** master_city_id */
  id: Scalars["Int"]["output"];
  /** 県名 */
  masterPrefecture: UsersMasterPrefectureType;
  /** 県ID */
  masterPrefectureId: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
};

export type UsersMasterPrefectureType = {
  __typename?: "UsersMasterPrefectureType";
  /** 都道府県に属する結合エリア */
  combinedAreas?: Maybe<Array<UsersCombinedAreaType>>;
  /** master_prefecture_id */
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  /** master_prefecture_slug */
  slug?: Maybe<Scalars["String"]["output"]>;
};

export type UsersMasterShopGenreType = {
  __typename?: "UsersMasterShopGenreType";
  /** master_shop_genre_id */
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
};

export type UsersMasterShopImageType = {
  __typename?: "UsersMasterShopImageType";
  /** master_shop_image_id */
  id: Scalars["Int"]["output"];
  /** image category name */
  name: Scalars["String"]["output"];
  /** required images num */
  requiredNumber: Scalars["Int"]["output"];
};

export type UsersMasterSportSeasonType = {
  __typename?: "UsersMasterSportSeasonType";
  /** master_sport_season_id */
  id: Scalars["Int"]["output"];
  /** master_sport_genre_id */
  masterSportGenreId: Scalars["Int"]["output"];
  /** 年度 */
  year: Scalars["Int"]["output"];
};

export type UsersMasterTournamentCategoryType = {
  __typename?: "UsersMasterTournamentCategoryType";
  /** master_tournament_category_label */
  label: Scalars["String"]["output"];
  /** master_tournament_category_sort */
  sort: Scalars["Int"]["output"];
};

export type UsersMatchByDateType = {
  __typename?: "UsersMatchByDateType";
  /** date of matches */
  date: Scalars["String"]["output"];
  /** matches */
  nodes?: Maybe<Array<UsersMatchType>>;
};

export type UsersMatchType = {
  __typename?: "UsersMatchType";
  /** アウェイチーム */
  awayTeam?: Maybe<UsersTeamType>;
  /** アウェイチームの表示名 */
  awayTeamDisplayFullName: Scalars["String"]["output"];
  /**
   * アウェイチームの表示名(短縮版)
   * @deprecated renamed to away_team_display_short_name
   */
  awayTeamDisplayName: Scalars["String"]["output"];
  /** アウェイチームの表示名(短縮版) */
  awayTeamDisplayShortName: Scalars["String"]["output"];
  /** アウェイチームアイコンのパス */
  awayTeamIconPath: Scalars["String"]["output"];
  /** スポンサーロゴのパス */
  contentsHolderLogoPath?: Maybe<Scalars["String"]["output"]>;
  /** ホームチーム */
  homeTeam?: Maybe<UsersTeamType>;
  /** ホームチームの表示名 */
  homeTeamDisplayFullName: Scalars["String"]["output"];
  /**
   * ホームチームの表示名(短縮版)
   * @deprecated renamed to home_team_display_short_name
   */
  homeTeamDisplayName: Scalars["String"]["output"];
  /** ホームチームの表示名(短縮版) */
  homeTeamDisplayShortName: Scalars["String"]["output"];
  /** ホームチームアイコンのパス */
  homeTeamIconPath: Scalars["String"]["output"];
  /** match_id */
  id: Scalars["Int"]["output"];
  /** 未定か否か */
  isTba?: Maybe<Scalars["Boolean"]["output"]>;
  /** ラベル */
  label?: Maybe<Scalars["String"]["output"]>;
  /** 大会情報 */
  masterTournament?: Maybe<UsersTournamentType>;
  /** 「○節」等の ○ 部分の数値 */
  number?: Maybe<Scalars["Int"]["output"]>;
  /** 試合を上映する店舗一覧 */
  shops: Array<UsersShopType>;
  /** 試合を上映する店舗数 */
  shopsCount: Scalars["Int"]["output"];
  /** 試合開始時刻 */
  startsAt: Scalars["String"]["output"];
  /** sectionが節、tournamentが回戦を表すENUM。numberカラムと合わせ使用 */
  style?: Maybe<MasterTournamentTypeEnum>;
  /** 特別な色を使用するか否か */
  useSpecialColor: Scalars["Boolean"]["output"];
  /** 試合会場 */
  venue?: Maybe<Scalars["String"]["output"]>;
};

export type UsersMaximumPointsToGiveEachFrameFrameType = {
  __typename?: "UsersMaximumPointsToGiveEachFrameFrameType";
  appOnSitePayment?: Maybe<Scalars["Int"]["output"]>;
  appOnSystemPayment?: Maybe<Scalars["Int"]["output"]>;
  reservationFrameId: Scalars["Int"]["output"];
  webOnSitePayment?: Maybe<Scalars["Int"]["output"]>;
  webOnSystemPayment?: Maybe<Scalars["Int"]["output"]>;
};

export type UsersMutationType = {
  __typename?: "UsersMutationType";
  applyCouponCode?: Maybe<Scalars["Boolean"]["output"]>;
  cancelReservation?: Maybe<CancelReservationResult>;
  completeReservationRequest?: Maybe<CompleteReservationRequestResult>;
  confirmUserPolicy?: Maybe<Scalars["Boolean"]["output"]>;
  createEmailOptInSetting?: Maybe<Scalars["Boolean"]["output"]>;
  createUserFavoriteShop?: Maybe<Scalars["Boolean"]["output"]>;
  createUserFavoriteTeam?: Maybe<UsersUserType>;
  deletePaymentMethod?: Maybe<DeletePaymentMethodResult>;
  deleteUserFavoriteShop?: Maybe<Scalars["Boolean"]["output"]>;
  deleteUserFavoriteTeam?: Maybe<UsersUserType>;
  deleteUserPushNotificationToken?: Maybe<Scalars["Boolean"]["output"]>;
  drawLottery?: Maybe<UsersLotteryEntryType>;
  exchangeGift?: Maybe<UserGiftExchange>;
  leave?: Maybe<Scalars["Boolean"]["output"]>;
  /** 変更のあったuser_notification_reads#idを返す。 */
  readNotification?: Maybe<Scalars["Int"]["output"]>;
  /** 現在時刻までのお知らせを既読にする(既読になったお知らせの件数を返す) */
  readNotifications?: Maybe<Scalars["Int"]["output"]>;
  recreateUserShopRating?: Maybe<UsersUserShopRatingType>;
  registerPushToken?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated Use requestReservationV3 */
  requestReservationV2?: Maybe<RequestReservationResult>;
  requestReservationV3?: Maybe<RequestReservationResultV3>;
  saveUserPushNotificationToken?: Maybe<Scalars["Boolean"]["output"]>;
  sendRegisteredMail?: Maybe<Scalars["Boolean"]["output"]>;
  signup?: Maybe<UsersUserType>;
  updateEmail?: Maybe<UsersUserType>;
  updateEmailNotificationSetting?: Maybe<UsersUserEmailNotificationSettingType>;
  updateFavoriteTeams?: Maybe<UsersUserType>;
  updateProfile?: Maybe<UsersUserType>;
  updatePushNotificationSetting?: Maybe<UsersUserPushNotificationSettingType>;
  useCoupon?: Maybe<UsersUserCouponType>;
  verifyEmail?: Maybe<UsersUserType>;
};

export type UsersMutationTypeApplyCouponCodeArgs = {
  input: ApplyCouponCodeInput;
};

export type UsersMutationTypeCancelReservationArgs = {
  input: CancelReservationInput;
};

export type UsersMutationTypeCompleteReservationRequestArgs = {
  input: CompleteReservationRequestInput;
};

export type UsersMutationTypeConfirmUserPolicyArgs = {
  input: ConfirmUserPolicyInput;
};

export type UsersMutationTypeCreateEmailOptInSettingArgs = {
  input: CreateEmailOptInSettingInput;
};

export type UsersMutationTypeCreateUserFavoriteShopArgs = {
  input: CreateUserFavoriteShopInput;
};

export type UsersMutationTypeCreateUserFavoriteTeamArgs = {
  input: CreateUserFavoriteTeamInput;
};

export type UsersMutationTypeDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};

export type UsersMutationTypeDeleteUserFavoriteShopArgs = {
  input: DeleteUserFavoriteShopInput;
};

export type UsersMutationTypeDeleteUserFavoriteTeamArgs = {
  input: DeleteUserFavoriteTeamInput;
};

export type UsersMutationTypeDeleteUserPushNotificationTokenArgs = {
  input: DeleteUserPushNotificationTokenInput;
};

export type UsersMutationTypeDrawLotteryArgs = {
  input: DrawLotteryInput;
};

export type UsersMutationTypeExchangeGiftArgs = {
  input: ExchangeGiftInput;
};

export type UsersMutationTypeLeaveArgs = {
  input: LeaveInput;
};

export type UsersMutationTypeReadNotificationArgs = {
  input: ReadNotificationInput;
};

export type UsersMutationTypeReadNotificationsArgs = {
  input: ReadNotificationsInput;
};

export type UsersMutationTypeRecreateUserShopRatingArgs = {
  input: RecreateUserShopRatingInput;
};

export type UsersMutationTypeRegisterPushTokenArgs = {
  input: RegisterPushTokenInput;
};

export type UsersMutationTypeRequestReservationV2Args = {
  input: RequestReservationV2Input;
};

export type UsersMutationTypeRequestReservationV3Args = {
  input: RequestReservationV3Input;
};

export type UsersMutationTypeSaveUserPushNotificationTokenArgs = {
  input: SaveUserPushNotificationTokenInput;
};

export type UsersMutationTypeSendRegisteredMailArgs = {
  input: SendRegisteredMailInput;
};

export type UsersMutationTypeSignupArgs = {
  input: SignupInput;
};

export type UsersMutationTypeUpdateEmailArgs = {
  input: UpdateEmailInput;
};

export type UsersMutationTypeUpdateEmailNotificationSettingArgs = {
  input: UpdateEmailNotificationSettingInput;
};

export type UsersMutationTypeUpdateFavoriteTeamsArgs = {
  input: UpdateFavoriteTeamsInput;
};

export type UsersMutationTypeUpdateProfileArgs = {
  input: UpdateProfileInput;
};

export type UsersMutationTypeUpdatePushNotificationSettingArgs = {
  input: UpdatePushNotificationSettingInput;
};

export type UsersMutationTypeUseCouponArgs = {
  input: UseCouponInput;
};

export type UsersMutationTypeVerifyEmailArgs = {
  input: VerifyEmailInput;
};

export type UsersNeighborShopsType = {
  __typename?: "UsersNeighborShopsType";
  /** 今見ている店舗のエリアのID */
  areaId?: Maybe<Scalars["Int"]["output"]>;
  /** 今見ている店舗のエリア */
  areaText: Scalars["String"]["output"];
  /** 今見ている店舗のエリアの種類 */
  areaType?: Maybe<AreaTypeEnum>;
  /** 更なる店舗が存在する */
  hasMore: Scalars["Boolean"]["output"];
  /** 店舗 */
  shops: Array<UsersShopType>;
};

export type UsersNewsArticleType = {
  __typename?: "UsersNewsArticleType";
  /** 本文 */
  body: Scalars["String"]["output"];
  /** ボタンラベル文言 */
  buttonLabel?: Maybe<Scalars["String"]["output"]>;
  /** ボタン遷移先URL */
  buttonLinkUrl?: Maybe<Scalars["String"]["output"]>;
  /** 公開終了日時 */
  endsAt: Scalars["String"]["output"];
  /** ID */
  id: Scalars["Int"]["output"];
  /** 画像ALTテキスト */
  imageAlt: Scalars["String"]["output"];
  /** 画像パス */
  imagePath: Scalars["String"]["output"];
  /** 画像URL */
  imageUrl: Scalars["String"]["output"];
  /** チーム */
  masterTeam?: Maybe<UsersTeamType>;
  /** 対象試合 */
  matches: Array<UsersMatchType>;
  /** ディスクリプション */
  metaDescription: Scalars["String"]["output"];
  /** 注意事項 */
  note?: Maybe<Scalars["String"]["output"]>;
  /** OGP画像パス */
  ogpImagePath: Scalars["String"]["output"];
  /** OGP画像URL */
  ogpImageUrl: Scalars["String"]["output"];
  /** 公開開始からの経過時間 */
  passedTimeLabel: Scalars["String"]["output"];
  /** 対象店舗 */
  shops: Array<UsersShopType>;
  /** 公開開始日時 */
  startsAt: Scalars["String"]["output"];
  /** タイトル */
  title: Scalars["String"]["output"];
};

export type UsersNewsArticleTypePage = {
  __typename?: "UsersNewsArticleTypePage";
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  nodes: Array<UsersNewsArticleType>;
  totalCount: Scalars["Int"]["output"];
  totalPage: Scalars["Int"]["output"];
};

export type UsersOnSystemPaymentCancelFeeType = {
  __typename?: "UsersOnSystemPaymentCancelFeeType";
  percentage: Scalars["Float"]["output"];
};

export type UsersPayloadsMaximumPointsToGiveEachFramePayload = {
  __typename?: "UsersPayloadsMaximumPointsToGiveEachFramePayload";
  items: Array<UsersMaximumPointsToGiveEachFrameFrameType>;
};

export type UsersPayloadsPaymentCardsPayload = {
  __typename?: "UsersPayloadsPaymentCardsPayload";
  canSaveMoreCards: Scalars["Boolean"]["output"];
  items: Array<UsersPaymentCardType>;
};

export type UsersPayloadsPointsToGivePayload = {
  __typename?: "UsersPayloadsPointsToGivePayload";
  points: Scalars["Int"]["output"];
};

export type UsersPayloadsRequestReservationPayload = {
  __typename?: "UsersPayloadsRequestReservationPayload";
  clientSecret?: Maybe<Scalars["String"]["output"]>;
  needsConfirmation: Scalars["Boolean"]["output"];
  reservation?: Maybe<UsersReservationType>;
  reservationUuid: Scalars["String"]["output"];
};

export type UsersPayloadsSuccessPayload = {
  __typename?: "UsersPayloadsSuccessPayload";
  success: Scalars["Boolean"]["output"];
};

export type UsersPaymentCardType = {
  __typename?: "UsersPaymentCardType";
  /** カードのブランド(Stripe APIから返ってくる文字列) */
  brand: Scalars["String"]["output"];
  /** カードの有効期限が切れているかどうか */
  isExpired: Scalars["Boolean"]["output"];
  /** カードの下4桁 */
  last4: Scalars["String"]["output"];
  stripePaymentMethodId: Scalars["String"]["output"];
};

export type UsersPersonalShopDataType = {
  __typename?: "UsersPersonalShopDataType";
  /** お気に入りに登録中かどうか */
  isFavorite: Scalars["Boolean"]["output"];
  /** 店舗UUID */
  uuid: Scalars["String"]["output"];
};

export type UsersQueryType = {
  __typename?: "UsersQueryType";
  /** Areas */
  areas: UsersAreaTypePage;
  /** Matches for Campaign LP */
  campaignMatches: Array<UsersMatchType>;
  /** 指定された都道府県の市区町村を全件返す */
  cities: Array<UsersMasterCityType>;
  /** CombinedAreas */
  combinedAreas: UsersCombinedAreaTypePage;
  /** ログイン中のユーザー情報を返却する */
  currentUser: UsersCurrentUserType;
  /** DaznOfficialShopListType */
  daznOfficialShops: UsersDaznOfficialShopResultType;
  /** フロントエンド(web, app)で使用する設定値 */
  fanstaConfig: UsersFanstaConfigType;
  /** お気に入りの店舗一覧を取得する */
  favoriteShops: UsersShopTypePage;
  /** お気に入りチームの最新のニュース1件を返す */
  favoriteTeamLatestNewsArticle?: Maybe<UsersNewsArticleType>;
  /** お気に入りチームの今後の試合を返す */
  favoriteTeamMatches: Array<UsersMatchByDateType>;
  /** お気に入りチームの試合を上映するお店一覧 */
  favoriteTeamShops: Array<UsersShopType>;
  /** 指定されたIDの商品を一件取得する */
  gift: UsersGiftType;
  /** 商品一覧を取得する */
  gifts: UsersGiftTypePage;
  /** 日本の祝日を返す */
  holidays: Array<UsersHolidayType>;
  /** latest master sport seasons */
  latestSportSeason: UsersMasterSportSeasonType;
  /** ユーザーのくじエントリー情報 */
  lotteryEntry: UsersLotteryEntryType;
  /** list of all MasterPrefectures */
  masterPrefectures: Array<UsersMasterPrefectureType>;
  /** list of all MasterShopImage */
  masterShopImages: Array<UsersMasterShopImageType>;
  /** 指定した試合情報を返す */
  match?: Maybe<UsersMatchType>;
  /** Matches */
  matches: Array<UsersMatchByDateType>;
  /** 条件にあてはまる各予約枠に応じた獲得ポイント一覧を返す */
  maximumPointsToGiveEachFrame: UsersPayloadsMaximumPointsToGiveEachFramePayload;
  /** Neighbor shops */
  neighborShops: UsersNeighborShopsType;
  /** 指定されたニュース記事を返す */
  newsArticle: UsersNewsArticleType;
  /** ニュースを新しい順に返す */
  newsArticles: UsersNewsArticleTypePage;
  onSystemPaymentCancelFee: UsersOnSystemPaymentCancelFeeType;
  paymentCards: PaymentCardsResult;
  /** 指定した店舗群についてユーザー特有の情報を返す */
  personalShopData: Array<UsersPersonalShopDataType>;
  /** 予約人数に応じた獲得できるポイント数を返す */
  pointsToGive: Scalars["Int"]["output"];
  /** 予約人数と支払い金額に応じた獲得できるポイント数を返す */
  pointsToGiveV2: UsersPayloadsPointsToGivePayload;
  /** Raw shops (skip pagination) */
  rawShops: Array<UsersShopType>;
  /** Reservation */
  reservation: UsersReservationType;
  /** 予約のキャンセル料と返金額 */
  reservationCancelInformation: UsersReservationCancelInformationType;
  /** 店舗の特定の試合もしくは日にちの予約枠一覧 */
  reservationFramesByDateOrMatch: Array<UsersReservationFrameType>;
  /** 店舗の特定の日の試合観戦一覧 */
  reservationMatchOptionsByDate: Array<UsersReservationMatchOptionType>;
  /** 予約によるポイント還元関連の定数 */
  reservationPaymentPointConstants: UsersReservationPaymentPointConstantsType;
  /** 店舗の来店可能日を返す */
  reservationVisitDates?: Maybe<Array<UsersReservationVisitDatesType>>;
  /** Reservations */
  reservations: UsersReservationTypePage;
  /** Shops */
  searchShops: UsersShopTypePage;
  /** return list of selected matches */
  selectedMatches: Array<UsersMatchType>;
  /** 指定した店舗情報を返す */
  shop: UsersShopType;
  /** ShopMatch */
  shopMatch: UsersShopMatchTypePage;
  /** おすすめ店舗情報一覧を返す */
  shopRecommends: Array<UsersShopType>;
  /** Sport Genres */
  sportGenres: Array<UsersSportGenreType>;
  /** 最新3件のニュースを新しい順に返す */
  teamLatestNewsArticles: Array<UsersNewsArticleType>;
  /** 指定したチームのオススメの試合・店舗情報等を返す */
  teamProfile: UsersTeamProfileType;
  /** 指定したチームのオススメの試合・店舗情報等を返す */
  teamRecommendShops: Array<UsersTeamRecommendShopType>;
  /** Teams */
  teams: UsersTeamTypePage;
  /** Teams */
  teamsUnbundled: Array<UsersTeamType>;
  /** トーナメントを全件返す */
  tournaments: Array<UsersTournamentType>;
  /** ログインユーザーのお知らせ未読数を返す */
  unreadCount: Scalars["Int"]["output"];
  /** 期限内のユーザーのクーポンから未使用、使用済みそれぞれの一覧を返す */
  userCoupons: UsersUserCouponsType;
  /** ユーザーの商品交換履歴を返す */
  userGiftExchanges: UserGiftExchangePage;
  /** ログインユーザーがアカウント新規登録以後に公開されたお知らせを公開開始日時の降順で取得する */
  userNotifications: UsersUserNotificationTypePage;
  userPointHistories: UserPointHistoryPage;
  /** 指定したユーザー&店舗の評価を返す */
  userShopRating?: Maybe<UsersUserShopRatingType>;
  /** 観戦環境を全件返す */
  viewEnvironments: Array<UsersViewEnvironmentType>;
};

export type UsersQueryTypeAreasArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  prefectureSlug?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type UsersQueryTypeCampaignMatchesArgs = {
  endTime?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  teamIds: Array<Scalars["Int"]["input"]>;
};

export type UsersQueryTypeCitiesArgs = {
  prefectureId: Scalars["Int"]["input"];
};

export type UsersQueryTypeCombinedAreasArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type UsersQueryTypeFavoriteShopsArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UsersQueryTypeGiftArgs = {
  giftId: Scalars["Int"]["input"];
};

export type UsersQueryTypeGiftsArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UsersQueryTypeLatestSportSeasonArgs = {
  masterSportGenreId: Scalars["Int"]["input"];
};

export type UsersQueryTypeLotteryEntryArgs = {
  uuid: Scalars["String"]["input"];
};

export type UsersQueryTypeMasterPrefecturesArgs = {
  slug?: InputMaybe<Scalars["String"]["input"]>;
  withCache?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UsersQueryTypeMatchArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UsersQueryTypeMatchesArgs = {
  baseYmd?: InputMaybe<Scalars["String"]["input"]>;
  inTwoWeeks?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  now?: InputMaybe<Scalars["String"]["input"]>;
  teamIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  tournamentId?: InputMaybe<Scalars["Int"]["input"]>;
  tournamentIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  tournamentIdsTba?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type UsersQueryTypeMaximumPointsToGiveEachFrameArgs = {
  date?: InputMaybe<Scalars["String"]["input"]>;
  matchId?: InputMaybe<Scalars["Int"]["input"]>;
  personNum: Scalars["Int"]["input"];
  shopUuid: Scalars["String"]["input"];
};

export type UsersQueryTypeNeighborShopsArgs = {
  shopUuid: Scalars["String"]["input"];
};

export type UsersQueryTypeNewsArticleArgs = {
  newsArticleId: Scalars["Int"]["input"];
};

export type UsersQueryTypeNewsArticlesArgs = {
  masterTeamSlug?: InputMaybe<Scalars["String"]["input"]>;
  matchId?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  teamIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type UsersQueryTypePersonalShopDataArgs = {
  shopUuids: Array<Scalars["String"]["input"]>;
};

export type UsersQueryTypePointsToGiveArgs = {
  isApp?: InputMaybe<Scalars["Boolean"]["input"]>;
  personNum: Scalars["Int"]["input"];
};

export type UsersQueryTypePointsToGiveV2Args = {
  isApp: Scalars["Boolean"]["input"];
  paymentAmount: Scalars["Int"]["input"];
  paymentType: ReservationPaymentTypeEnum;
  personNum: Scalars["Int"]["input"];
};

export type UsersQueryTypeRawShopsArgs = {
  uuids: Array<Scalars["String"]["input"]>;
};

export type UsersQueryTypeReservationArgs = {
  reservationUuid: Scalars["String"]["input"];
};

export type UsersQueryTypeReservationCancelInformationArgs = {
  uuid: Scalars["String"]["input"];
};

export type UsersQueryTypeReservationFramesByDateOrMatchArgs = {
  date?: InputMaybe<Scalars["String"]["input"]>;
  matchId?: InputMaybe<Scalars["Int"]["input"]>;
  personNum?: InputMaybe<Scalars["Int"]["input"]>;
  shopUuid: Scalars["String"]["input"];
};

export type UsersQueryTypeReservationMatchOptionsByDateArgs = {
  date: Scalars["String"]["input"];
  shopUuid: Scalars["String"]["input"];
};

export type UsersQueryTypeReservationVisitDatesArgs = {
  shopUuid: Scalars["String"]["input"];
};

export type UsersQueryTypeReservationsArgs = {
  asc?: InputMaybe<Scalars["Boolean"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UsersQueryTypeSearchShopsArgs = {
  areaIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  combinedAreaIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  matchId?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  teamIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  tournamentId?: InputMaybe<Scalars["Int"]["input"]>;
  tournamentIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  tournamentIdsTba?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type UsersQueryTypeSelectedMatchesArgs = {
  shopUuid: Scalars["String"]["input"];
};

export type UsersQueryTypeShopArgs = {
  shopUuid: Scalars["String"]["input"];
};

export type UsersQueryTypeShopMatchArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  shopUuid: Scalars["String"]["input"];
};

export type UsersQueryTypeSportGenresArgs = {
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type UsersQueryTypeTeamLatestNewsArticlesArgs = {
  masterTeamSlug: Scalars["String"]["input"];
  newsArticleId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UsersQueryTypeTeamProfileArgs = {
  slug: Scalars["String"]["input"];
};

export type UsersQueryTypeTeamRecommendShopsArgs = {
  slug?: InputMaybe<Scalars["String"]["input"]>;
  teamId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UsersQueryTypeTeamsArgs = {
  masterSportGenreId?: InputMaybe<Scalars["Int"]["input"]>;
  masterTournament?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UsersQueryTypeTeamsUnbundledArgs = {
  slug?: InputMaybe<Scalars["String"]["input"]>;
  sportGenreSlug?: InputMaybe<Scalars["String"]["input"]>;
  tournamentSlug?: InputMaybe<Scalars["String"]["input"]>;
  withCache?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UsersQueryTypeTournamentsArgs = {
  ids?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  withCache?: InputMaybe<Scalars["Boolean"]["input"]>;
  withCategory?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UsersQueryTypeUserGiftExchangesArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UsersQueryTypeUserNotificationsArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UsersQueryTypeUserPointHistoriesArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UsersQueryTypeUserShopRatingArgs = {
  shopUuid: Scalars["String"]["input"];
};

export type UsersQueryTypeViewEnvironmentsArgs = {
  shopUuid: Scalars["String"]["input"];
};

export type UsersReservationCancelInformationType = {
  __typename?: "UsersReservationCancelInformationType";
  /** キャンセル手数料の適用金額 */
  cancelFeeAmount: Scalars["Int"]["output"];
  /** キャンセル手数料の適用率 */
  cancelFeePercentage: Scalars["Float"]["output"];
  /** キャンセルポリシーの適用金額 */
  cancelPolicyAmount: Scalars["Int"]["output"];
  /** キャンセルポリシーの適用率 */
  cancelPolicyPercentage: Scalars["Int"]["output"];
  /** 返金額 */
  refundAmount: Scalars["Int"]["output"];
  /** 返還ポイント */
  refundPoints: Scalars["Int"]["output"];
};

export type UsersReservationFrameType = {
  __typename?: "UsersReservationFrameType";
  date: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  endsAt?: Maybe<Scalars["String"]["output"]>;
  /** 同日に上映予定があるかどうか */
  hasScheduledMatch?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["Int"]["output"];
  isInReservableTime: Scalars["Boolean"]["output"];
  match?: Maybe<UsersMatchType>;
  maxPersonNum: Scalars["Int"]["output"];
  newsArticles: Array<UsersNewsArticleType>;
  notes: Scalars["String"]["output"];
  reservationPlan: UsersReservationPlanType;
  reservationType: ReservationFrameTypeEnum;
  startsAt?: Maybe<Scalars["String"]["output"]>;
  state: ReservationFrameStateTypeEnum;
  timeOptions: Array<Scalars["String"]["output"]>;
};

export type UsersReservationMatchOptionType = {
  __typename?: "UsersReservationMatchOptionType";
  match?: Maybe<UsersMatchType>;
  newsArticles: Array<UsersNewsArticleType>;
};

export type UsersReservationPaymentPointConstantsType = {
  __typename?: "UsersReservationPaymentPointConstantsType";
  /** 最大還元ポイント数（現地決済） */
  maximumPointsOnSite: Scalars["Int"]["output"];
  /** 最大還元ポイント数（事前決済） */
  maximumPointsOnSystem: Scalars["Int"]["output"];
  /** 人数ベースポイントでの人数あたりの還元ポイント数（アプリ予約） */
  pointsPerPersonApp: Scalars["Int"]["output"];
  /** 人数ベースポイントでの人数あたりの還元ポイント数（Web予約） */
  pointsPerPersonWeb: Scalars["Int"]["output"];
  /** 金額ベースポイントでの還元率（アプリ予約・現地決済） */
  priceBasedPercentageAppOnSite: Scalars["Float"]["output"];
  /** 金額ベースポイントでの還元率（アプリ予約・事前決済） */
  priceBasedPercentageAppOnSystem: Scalars["Float"]["output"];
  /** 金額ベースポイントでの還元率（Web予約・現地決済） */
  priceBasedPercentageWebOnSite: Scalars["Float"]["output"];
  /** 金額ベースポイントでの還元率（Web予約・事前決済） */
  priceBasedPercentageWebOnSystem: Scalars["Float"]["output"];
};

export type UsersReservationPaymentType = {
  __typename?: "UsersReservationPaymentType";
  appliedPoints: Scalars["Int"]["output"];
  cardBrand?: Maybe<Scalars["String"]["output"]>;
  /** カードの下4桁 */
  cardLast4?: Maybe<Scalars["String"]["output"]>;
  /** 支払い方法 */
  paymentMethodType: ReservationPaymentMethodTypeEnum;
  reservationRefund?: Maybe<UsersReservationRefundType>;
};

export type UsersReservationPlanCancelPolicyRefundPercentageType = {
  __typename?: "UsersReservationPlanCancelPolicyRefundPercentageType";
  before1Day: Scalars["Int"]["output"];
  before2Days: Scalars["Int"]["output"];
  before3Days: Scalars["Int"]["output"];
  onTheDay: Scalars["Int"]["output"];
};

export type UsersReservationPlanCancelPolicyType = {
  __typename?: "UsersReservationPlanCancelPolicyType";
  refundPercentage: UsersReservationPlanCancelPolicyRefundPercentageType;
};

export type UsersReservationPlanType = {
  __typename?: "UsersReservationPlanType";
  allowedPaymentType: ReservationPlanAllowedPaymentTypeEnum;
  cancelPolicy?: Maybe<UsersReservationPlanCancelPolicyType>;
  description: Scalars["String"]["output"];
  endsAt?: Maybe<Scalars["String"]["output"]>;
  entranceFee: Scalars["Int"]["output"];
  fee: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  imagePath?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  lockVersion: Scalars["Int"]["output"];
  maxPersonNum: Scalars["Int"]["output"];
  minPersonNum: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  notes: Scalars["String"]["output"];
  startsAt?: Maybe<Scalars["String"]["output"]>;
  totalFee: Scalars["Int"]["output"];
};

export type UsersReservationRefundType = {
  __typename?: "UsersReservationRefundType";
  /** キャンセル手数料の適用率 */
  cancelFeePercentage: Scalars["Float"]["output"];
  /** キャンセルポリシーの適用金額 */
  cancelPolicyAmount: Scalars["Int"]["output"];
  /** キャンセルポリシーの適用率 */
  cancelPolicyPercentage: Scalars["Int"]["output"];
  /** 返金額 */
  refundAmount: Scalars["Int"]["output"];
  /** 返還ポイント */
  refundPoints: Scalars["Int"]["output"];
  /** キャンセル手数料の適用金額 */
  userCancelFeeAmount: Scalars["Int"]["output"];
};

export type UsersReservationSupportingTeamType = {
  __typename?: "UsersReservationSupportingTeamType";
  id: Scalars["Int"]["output"];
  /** @deprecated クライアント側で使用しないダミーの値 */
  reservationId: Scalars["Int"]["output"];
  supportingTeam?: Maybe<UsersTeamType>;
};

export type UsersReservationType = {
  __typename?: "UsersReservationType";
  cancelMessage?: Maybe<Scalars["String"]["output"]>;
  cancelPolicy?: Maybe<UsersReservationPlanCancelPolicyType>;
  /** @deprecated Use cancel_type_v2 */
  cancelType?: Maybe<ReservationCancelTypeEnum>;
  cancelTypeV2?: Maybe<ReservationCancelTypeEnumV2>;
  comesAt: Scalars["String"]["output"];
  confirmationDeadlineText: Scalars["String"]["output"];
  enterableLotteryCampaignIds: Array<Scalars["Int"]["output"]>;
  /** 1人あたりのエントランス代 */
  entranceFee: Scalars["Int"]["output"];
  /** 1人あたりの飲食代 */
  fee: Scalars["Int"]["output"];
  firstName: Scalars["String"]["output"];
  firstNameKana: Scalars["String"]["output"];
  givenPoints?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["Int"]["output"];
  isCancellable: Scalars["Boolean"]["output"];
  lastName: Scalars["String"]["output"];
  lastNameKana: Scalars["String"]["output"];
  match?: Maybe<UsersMatchType>;
  /** ポイント利用分を差し引いた決済の合計金額 */
  paymentAmount: Scalars["Int"]["output"];
  /** 決済方法 */
  paymentType: ReservationPaymentTypeEnum;
  personNum: Scalars["Int"]["output"];
  planDescription: Scalars["String"]["output"];
  planName: Scalars["String"]["output"];
  planNotes: Scalars["String"]["output"];
  reservationFrame: UsersReservationFrameType;
  reservationPayment?: Maybe<UsersReservationPaymentType>;
  reservationSupportingTeam?: Maybe<UsersReservationSupportingTeamType>;
  shop: UsersShopType;
  state: ReservationStateTypeEnum;
  tel: Scalars["String"]["output"];
  telWithHyphen: Scalars["String"]["output"];
  /** ポイント利用分も含んだ決済の合計金額(小計) */
  totalAmount: Scalars["Int"]["output"];
  uuid: Scalars["String"]["output"];
};

export type UsersReservationTypePage = {
  __typename?: "UsersReservationTypePage";
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  nodes: Array<UsersReservationType>;
  totalCount: Scalars["Int"]["output"];
  totalPage: Scalars["Int"]["output"];
};

export type UsersReservationVisitDatesType = {
  __typename?: "UsersReservationVisitDatesType";
  date: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
};

export type UsersShopCalendarDayType = {
  __typename?: "UsersShopCalendarDayType";
  /** 日付 */
  date: Scalars["String"]["output"];
  /** 遷移した際に選択される試合 */
  defaultReservableMatchId?: Maybe<Scalars["Int"]["output"]>;
  /** 試合あり */
  hasMatch: Scalars["Boolean"]["output"];
  /** 祝日 */
  isHoliday: Scalars["Boolean"]["output"];
  /** 予約可能性 */
  reservability: ShopReservabilityTypeEnum;
};

export type UsersShopImageType = {
  __typename?: "UsersShopImageType";
  /** shop_image_id */
  id: Scalars["Int"]["output"];
  /** 画像path */
  imagePath: Scalars["String"]["output"];
  /** 画像URL */
  imageUrl: Scalars["String"]["output"];
  /** master shop image id */
  masterShopImageId: Scalars["Int"]["output"];
};

export type UsersShopMatchType = {
  __typename?: "UsersShopMatchType";
  /** shop_match_id */
  id: Scalars["Int"]["output"];
  /** 試合情報 */
  match: UsersMatchType;
  /** 店舗情報 */
  shop: UsersShopType;
};

export type UsersShopMatchTypePage = {
  __typename?: "UsersShopMatchTypePage";
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  nodes: Array<UsersShopMatchType>;
  totalCount: Scalars["Int"]["output"];
  totalPage: Scalars["Int"]["output"];
};

export type UsersShopRatingType = {
  __typename?: "UsersShopRatingType";
  /** shop_rating_id */
  id: Scalars["Int"]["output"];
  /** 総合評価 */
  ratingCalculated: Scalars["Float"]["output"];
  /** 評価件数 */
  ratingCount: Scalars["Int"]["output"];
  /** 総合評価を表示するかどうか */
  shouldShowRating: Scalars["Boolean"]["output"];
};

export type UsersShopType = {
  __typename?: "UsersShopType";
  /** 交通アクセス */
  access: Scalars["String"]["output"];
  /** 店舗特典 */
  benefit?: Maybe<UsersBenefitType>;
  /** 番地 */
  block: Scalars["String"]["output"];
  /** 予算 */
  budget: Scalars["String"]["output"];
  /** 建物名等 */
  building?: Maybe<Scalars["String"]["output"]>;
  /** 予約カレンダー */
  calendarDays: Array<UsersShopCalendarDayType>;
  /** キャンペーンラベル */
  campaignLabels: Array<UsersCampaignLabelType>;
  /** 事前決済可能な予約枠を持っているかどうか */
  canReserveOnSystemPayment: Scalars["Boolean"]["output"];
  /** 事前決済が可能かどうか */
  canUseOnSystemPayment: Scalars["Boolean"]["output"];
  /** 駐車場の有無 */
  carPark: Scalars["Boolean"]["output"];
  /** 駐車場の詳細 */
  carParkDescription?: Maybe<Scalars["String"]["output"]>;
  /** サービス/チャージ料 */
  chargeFee?: Maybe<Scalars["String"]["output"]>;
  /** 市区町村 */
  city: Scalars["String"]["output"];
  /** 最寄り駅 */
  closestStation: Scalars["String"]["output"];
  /** 最寄り駅からの徒歩○分 */
  closestStationTime: Scalars["Int"]["output"];
  /** 説明の本文 */
  descriptionBody: Scalars["String"]["output"];
  /** 説明の見出し */
  descriptionTitle: Scalars["String"]["output"];
  /** ネット予約可能かどうか */
  isReservableOnline: Scalars["Boolean"]["output"];
  /** 予約機能が有効かどうか */
  isReservationActive: Scalars["Boolean"]["output"];
  /** 対戦カード */
  latestMatch?: Maybe<UsersMatchType>;
  /** 地区 */
  masterArea: UsersAreaType;
  /** 都道府県 */
  masterPrefecture: UsersMasterPrefectureType;
  /** 店舗のジャンル */
  masterShopGenre: UsersMasterShopGenreType;
  /** 対戦カード */
  matches: Array<UsersMatchType>;
  /** 付与できるポイント数の最大値 */
  maxPossiblePoints: Scalars["Int"]["output"];
  /** モニター */
  monitor: Scalars["String"]["output"];
  /** 店舗名 */
  name: Scalars["String"]["output"];
  /** 店舗ニュース記事 */
  newsArticles: Array<UsersNewsArticleType>;
  /** 営業時間 */
  openingHours: Scalars["String"]["output"];
  /** 支払い方法 */
  paymentMethod: Scalars["String"]["output"];
  /** 郵便番号 */
  postcode: Scalars["String"]["output"];
  /** 定休日 */
  regularHoliday: Scalars["String"]["output"];
  /** 予約可能な試合一覧 */
  reservableMatches: Array<UsersMatchType>;
  /** 衛生対策 */
  sanitation?: Maybe<Scalars["String"]["output"]>;
  /** 座席 */
  seat: Scalars["String"]["output"];
  /** 画像一覧 */
  shopImages: Array<UsersShopImageType>;
  /** 店舗評価 */
  shopRating?: Maybe<UsersShopRatingType>;
  /** 禁煙喫煙 */
  smoking: Scalars["String"]["output"];
  /** Stripeアカウント */
  stripeAccount?: Maybe<UsersStripeAccountType>;
  /** 店舗の電話番号 */
  tel: Scalars["String"]["output"];
  /** 店舗の電話番号(ハイフン付き) */
  telWithHyphen: Scalars["String"]["output"];
  /** サムネイル用画像一覧 */
  thumbnailImages: Array<UsersShopImageType>;
  /** 店舗のウェブサイトURL */
  url?: Maybe<Scalars["String"]["output"]>;
  /** shop_uuid */
  uuid: Scalars["String"]["output"];
  /** 観戦環境一覧 */
  viewEnvironments: Array<UsersViewEnvironmentType>;
};

export type UsersShopTypeIsReservableOnlineArgs = {
  matchId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UsersShopTypeLatestMatchArgs = {
  matchId?: InputMaybe<Scalars["Int"]["input"]>;
  matchIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  onlyFavorite?: InputMaybe<Scalars["Boolean"]["input"]>;
  teamIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  tournamentIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  tournamentIdsTba?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type UsersShopTypeMatchesArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UsersShopTypeShopImagesArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UsersShopTypeViewEnvironmentsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UsersShopTypePage = {
  __typename?: "UsersShopTypePage";
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  nodes: Array<UsersShopType>;
  totalCount: Scalars["Int"]["output"];
  totalPage: Scalars["Int"]["output"];
};

export type UsersSportGenreType = {
  __typename?: "UsersSportGenreType";
  /** master_sport_genre_id */
  id: Scalars["Int"]["output"];
  /** スポーツジャンル名 */
  name?: Maybe<Scalars["String"]["output"]>;
  /** スポーツジャンルスラッグ */
  slug?: Maybe<Scalars["String"]["output"]>;
};

export type UsersStripeAccountType = {
  __typename?: "UsersStripeAccountType";
  /** カード決済が有効かどうか */
  isCardPaymentsActive: Scalars["Boolean"]["output"];
  /** JCB決済が有効かどうか */
  isJcbPaymentsActive: Scalars["Boolean"]["output"];
  /** StripeアカウントID */
  stripeAccountId?: Maybe<Scalars["String"]["output"]>;
};

export type UsersTeamProfileType = {
  __typename?: "UsersTeamProfileType";
  /** 対象チームの試合情報一覧 */
  matches: Array<UsersMatchType>;
  /** 対象チーム情報 */
  team: UsersTeamType;
};

export type UsersTeamRecommendShopType = {
  __typename?: "UsersTeamRecommendShopType";
  /** 試合情報 */
  match: UsersMatchType;
  /** おすすめのお店一覧 */
  recommendShops: Array<UsersShopType>;
};

export type UsersTeamType = {
  __typename?: "UsersTeamType";
  /** 背景無しチーム画像パス(代替) */
  altIconPath?: Maybe<Scalars["String"]["output"]>;
  /** 背景無しチーム画像パス */
  iconPath?: Maybe<Scalars["String"]["output"]>;
  /** team_id */
  id: Scalars["Int"]["output"];
  /** スポーツジャンル情報 */
  masterSportGenre: UsersSportGenreType;
  /** リーグ情報 */
  masterTournaments?: Maybe<Array<UsersTournamentType>>;
  /** チーム名 */
  name: Scalars["String"]["output"];
  /** チーム名(略称) */
  shortName: Scalars["String"]["output"];
  /** team_slug */
  slug?: Maybe<Scalars["String"]["output"]>;
  /** チームページのキービジュアルパス */
  teamPageKeyVisualPath?: Maybe<Scalars["String"]["output"]>;
  /** 背景有りチーム画像パス */
  thumbnailPath?: Maybe<Scalars["String"]["output"]>;
  /** 最優先リーグ */
  topPriorityTournament?: Maybe<UsersTournamentType>;
};

export type UsersTeamTypePage = {
  __typename?: "UsersTeamTypePage";
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  nodes: Array<UsersTeamType>;
  totalCount: Scalars["Int"]["output"];
  totalPage: Scalars["Int"]["output"];
};

export type UsersTournamentType = {
  __typename?: "UsersTournamentType";
  /** 検索ポップアップの全チーム選択チェックボックスに使うラベル名 */
  allTeamsLabel: Scalars["String"]["output"];
  /** 大会名 */
  displayName: Scalars["String"]["output"];
  /** 未定チームが含まれるかどうか */
  hasTba?: Maybe<Scalars["Boolean"]["output"]>;
  /** master_tournament_id */
  id: Scalars["Int"]["output"];
  /** お気に入り対象チームかどうか */
  isFavoriteTarget: Scalars["Boolean"]["output"];
  /** スポーツジャンル情報 */
  masterSportGenre: UsersSportGenreType;
  /** category */
  masterTournamentCategory: UsersMasterTournamentCategoryType;
  /** 大会名 */
  name: Scalars["String"]["output"];
  /** チームの表示に代替アイコン画像を使用する必要があるかどうか */
  requiresAltTeamIcons: Scalars["Boolean"]["output"];
  /** 大会名 */
  shortName: Scalars["String"]["output"];
  /** Powered by DAZNやOrganized by DAZNの表記対象かどうか */
  shouldShowDazn: Scalars["Boolean"]["output"];
  /** tournament_slug */
  slug?: Maybe<Scalars["String"]["output"]>;
  /** style */
  style: MasterTournamentTypeEnum;
  /** チーム未定画像のパス */
  tbaImagePath: Scalars["String"]["output"];
};

export type UsersUserConfirmationModalType = {
  __typename?: "UsersUserConfirmationModalType";
  policyType: UserPolicyTypeEnum;
  policyVersion: UserPolicyVersionEnum;
};

export type UsersUserCouponType = {
  __typename?: "UsersUserCouponType";
  /** クーポン */
  coupon: UsersCouponType;
  /** 有効期限表示日時 */
  displayedExpiration: Scalars["String"]["output"];
  /** id */
  id: Scalars["Int"]["output"];
  /** 使用された日時 */
  usedAt?: Maybe<Scalars["String"]["output"]>;
};

export type UsersUserCouponsType = {
  __typename?: "UsersUserCouponsType";
  /** 使用可能クーポン一覧 */
  available: Array<UsersUserCouponType>;
  /** 使用不可(使用済)クーポン一覧 */
  notAvailable: Array<UsersUserCouponType>;
};

export type UsersUserEmailNotificationSettingType = {
  __typename?: "UsersUserEmailNotificationSettingType";
  /** クーポンのお知らせ */
  coupon: Scalars["Boolean"]["output"];
  /** お気に入りチームのお知らせ */
  favoriteTeam: Scalars["Boolean"]["output"];
  /** お気に入りチームの試合のお知らせ */
  favoriteTeamMatch: Scalars["Boolean"]["output"];
  /** 運営からのお知らせ */
  fromAdmin: Scalars["Boolean"]["output"];
};

export type UsersUserExpirationPointHistoryType = UserPointHistory & {
  __typename?: "UsersUserExpirationPointHistoryType";
  /** 適用した日時（付与日、処理日、失効日、発行日にあたるもの） */
  appliedAt: Scalars["String"]["output"];
  /** 〇〇日を示すラベル */
  appliedAtLabel: Scalars["String"]["output"];
  /** ID */
  id: Scalars["Int"]["output"];
  /** ラベル名 */
  label: Scalars["String"]["output"];
  /** 加算or減算されたポイント数 */
  points: Scalars["Int"]["output"];
};

export type UsersUserGiftExchangePointHistoryType = UserPointHistory & {
  __typename?: "UsersUserGiftExchangePointHistoryType";
  /** 適用した日時（付与日、処理日、失効日、発行日にあたるもの） */
  appliedAt: Scalars["String"]["output"];
  /** 〇〇日を示すラベル */
  appliedAtLabel: Scalars["String"]["output"];
  /** ID */
  id: Scalars["Int"]["output"];
  /** ラベル名 */
  label: Scalars["String"]["output"];
  /** 加算or減算されたポイント数 */
  points: Scalars["Int"]["output"];
};

export type UsersUserGifteeGiftExchangeType = UserGiftExchange & {
  __typename?: "UsersUserGifteeGiftExchangeType";
  /** 商品交換日時 */
  createdAt: Scalars["String"]["output"];
  /** 商品URL有効期限 */
  expiresAt?: Maybe<Scalars["String"]["output"]>;
  /** 商品 */
  gift: UsersGiftType;
  /** 商品交換の状況 */
  gifteeGiftExchangeState: GifteeGiftExchangeStateEnum;
  /** 商品URL */
  url?: Maybe<Scalars["String"]["output"]>;
};

export type UsersUserLotteryPointHistoryType = UserPointHistory & {
  __typename?: "UsersUserLotteryPointHistoryType";
  /** 適用した日時（付与日、処理日、失効日、発行日にあたるもの） */
  appliedAt: Scalars["String"]["output"];
  /** 〇〇日を示すラベル */
  appliedAtLabel: Scalars["String"]["output"];
  /** ID */
  id: Scalars["Int"]["output"];
  /** ラベル名 */
  label: Scalars["String"]["output"];
  /** 加算or減算されたポイント数 */
  points: Scalars["Int"]["output"];
};

export type UsersUserNotificationType = {
  __typename?: "UsersUserNotificationType";
  /** アイコンの種類 */
  iconType: UserNotificationIconTypeEnum;
  /** notification_id */
  id: Scalars["Int"]["output"];
  /** 既読かどうか */
  isRead: Scalars["Boolean"]["output"];
  /** リンクURL */
  linkUrl: Scalars["String"]["output"];
  /** 公開期間開始日時からどれくらい経ったか */
  passedTimeLabel: Scalars["String"]["output"];
  /** 公開期間開始日時 */
  startsAt: Scalars["String"]["output"];
  /** お知らせタイトル */
  title: Scalars["String"]["output"];
};

export type UsersUserNotificationTypePage = {
  __typename?: "UsersUserNotificationTypePage";
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  nodes: Array<UsersUserNotificationType>;
  totalCount: Scalars["Int"]["output"];
  totalPage: Scalars["Int"]["output"];
};

export type UsersUserOtherPointHistoryType = UserPointHistory & {
  __typename?: "UsersUserOtherPointHistoryType";
  /** 適用した日時（付与日、処理日、失効日、発行日にあたるもの） */
  appliedAt: Scalars["String"]["output"];
  /** 〇〇日を示すラベル */
  appliedAtLabel: Scalars["String"]["output"];
  /** ID */
  id: Scalars["Int"]["output"];
  /** ラベル名 */
  label: Scalars["String"]["output"];
  /** 加算or減算されたポイント数 */
  points: Scalars["Int"]["output"];
};

export type UsersUserPointPaymentCancelHistoryType = UserPointHistory & {
  __typename?: "UsersUserPointPaymentCancelHistoryType";
  /** 適用した日時（付与日、処理日、失効日、発行日にあたるもの） */
  appliedAt: Scalars["String"]["output"];
  /** 〇〇日を示すラベル */
  appliedAtLabel: Scalars["String"]["output"];
  /** ID */
  id: Scalars["Int"]["output"];
  /** ラベル名 */
  label: Scalars["String"]["output"];
  /** 加算or減算されたポイント数 */
  points: Scalars["Int"]["output"];
};

export type UsersUserPointPaymentHistoryType = UserPointHistory & {
  __typename?: "UsersUserPointPaymentHistoryType";
  /** 適用した日時（付与日、処理日、失効日、発行日にあたるもの） */
  appliedAt: Scalars["String"]["output"];
  /** 〇〇日を示すラベル */
  appliedAtLabel: Scalars["String"]["output"];
  /** ID */
  id: Scalars["Int"]["output"];
  /** ラベル名 */
  label: Scalars["String"]["output"];
  /** 加算or減算されたポイント数 */
  points: Scalars["Int"]["output"];
  /** 来店日時 */
  visitedAt: Scalars["String"]["output"];
};

export type UsersUserProfileType = {
  __typename?: "UsersUserProfileType";
  birthdate: Scalars["String"]["output"];
  /** 居住エリア */
  city: UsersMasterCityType;
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameKana?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameKana?: Maybe<Scalars["String"]["output"]>;
  nickname?: Maybe<Scalars["String"]["output"]>;
  userId: Scalars["Int"]["output"];
};

export type UsersUserPushNotificationSettingType = {
  __typename?: "UsersUserPushNotificationSettingType";
  /** クーポンのお知らせ */
  coupon: Scalars["Boolean"]["output"];
  /** お気に入りチームのお知らせ */
  favoriteTeam: Scalars["Boolean"]["output"];
  /** お気に入りチームの試合のお知らせ */
  favoriteTeamMatch: Scalars["Boolean"]["output"];
  /** 運営からのお知らせ */
  fromAdmin: Scalars["Boolean"]["output"];
};

export type UsersUserReservationPointHistoryType = UserPointHistory & {
  __typename?: "UsersUserReservationPointHistoryType";
  /** 適用した日時（付与日、処理日、失効日、発行日にあたるもの） */
  appliedAt: Scalars["String"]["output"];
  /** 〇〇日を示すラベル */
  appliedAtLabel: Scalars["String"]["output"];
  /** ID */
  id: Scalars["Int"]["output"];
  /** ラベル名 */
  label: Scalars["String"]["output"];
  /** 加算or減算されたポイント数 */
  points: Scalars["Int"]["output"];
  /** 予約UUID */
  reservationUuid: Scalars["String"]["output"];
  /** 来店日時 */
  visitedAt: Scalars["String"]["output"];
};

export type UsersUserShopRatingType = {
  __typename?: "UsersUserShopRatingType";
  /** user_shop_rating_id */
  id: Scalars["Int"]["output"];
  /** レーティング更新日時 */
  ratedAt: Scalars["String"]["output"];
  /** レーティングの値 */
  rating: Scalars["Int"]["output"];
};

export type UsersUserType = {
  __typename?: "UsersUserType";
  /** 表示させるべき同意モーダル */
  confirmationModals: Array<UsersUserConfirmationModalType>;
  /** クーポンの数 */
  couponCount: Scalars["Int"]["output"];
  /** email address */
  email?: Maybe<Scalars["String"]["output"]>;
  /** メール通知設定 */
  emailNotificationSetting: UsersUserEmailNotificationSettingType;
  /** メールアドレスの認証済みか */
  emailVerified: Scalars["Boolean"]["output"];
  /** お気に入りチーム */
  favoriteTeams: Array<UsersTeamType>;
  /** uid of firebase */
  firebaseUid: Scalars["String"]["output"];
  /** user id */
  id: Scalars["Int"]["output"];
  /** オプトインかアウト回答済かどうか */
  isAnsweredOptingQuestion: Scalars["Boolean"]["output"];
  /** 現在保有しているポイント数 */
  points: Scalars["Int"]["output"];
  /** ポイントの有効期限 */
  pointsExpiresAt?: Maybe<Scalars["String"]["output"]>;
  /** プロフィール */
  profile: UsersUserProfileType;
  /** Push通知設定 */
  pushNotificationSetting: UsersUserPushNotificationSettingType;
  /** phone number */
  tel?: Maybe<Scalars["String"]["output"]>;
  /** phone number with hyphen */
  telWithHyphen: Scalars["String"]["output"];
  /** 更新日時 */
  updatedAt?: Maybe<Scalars["String"]["output"]>;
  /** user uuid */
  uuid: Scalars["String"]["output"];
};

export type UsersViewEnvironmentType = {
  __typename?: "UsersViewEnvironmentType";
  /** 説明 */
  description: Scalars["String"]["output"];
  /** view_environment_id */
  id: Scalars["Int"]["output"];
  /** 画像URL */
  imageUrl: Scalars["String"]["output"];
};

/** Autogenerated input type of VerifyEmail */
export type VerifyEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  uuid: Scalars["String"]["input"];
};

export type TournamentMatchBox_MatchListItemFragment = {
  __typename?: "UsersMatchType";
  id: number;
  startsAt: string;
  isTba?: boolean | null;
  label?: string | null;
  contentsHolderLogoPath?: string | null;
  homeTeamDisplayShortName: string;
  awayTeamDisplayShortName: string;
  homeTeamIconPath: string;
  awayTeamIconPath: string;
  useSpecialColor: boolean;
  shopsCount: number;
  awayTeam?: { __typename?: "UsersTeamType"; id: number; name: string } | null;
  homeTeam?: { __typename?: "UsersTeamType"; id: number; name: string } | null;
  masterTournament?: {
    __typename?: "UsersTournamentType";
    id: number;
    slug?: string | null;
    name: string;
    displayName: string;
    masterSportGenre: {
      __typename?: "UsersSportGenreType";
      id: number;
      slug?: string | null;
    };
  } | null;
};

export const TournamentMatchBox_MatchListItemFragmentDoc = gql`
  fragment TournamentMatchBox_MatchListItem on UsersMatchType {
    id
    awayTeam {
      id
      name
    }
    homeTeam {
      id
      name
    }
    masterTournament {
      id
      slug
      name
      displayName
      masterSportGenre {
        id
        slug
      }
    }
    startsAt
    isTba
    label
    contentsHolderLogoPath
    homeTeamDisplayShortName
    awayTeamDisplayShortName
    homeTeamIconPath
    awayTeamIconPath
    useSpecialColor
    shopsCount
  }
`;
